<template>
  <app-module-view>
    <template slot="body">
      <app-wood-wing-filter></app-wood-wing-filter>
      <div class="top-buttons">
        <div class="d-inline-block">
          <app-checkbox
            id="ww_autorefresh"
            v-model="autoRefresh"
            :label="$t('auto_refresh')">
          </app-checkbox>
        </div>
      </div>
      <div v-if="autoRefresh">
        <app-autoloader :callback="getWoodWing" :height="2" :interval="120"></app-autoloader>
      </div>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="woodWing"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import Preloader from '../preloader/Preloader'
import WoodWingFilter from './WoodWingFilter'
import DataTable from '../shared/DataTable'
import Autoloader from '../Autoloader'
import Checkbox from '../form/Checkbox'
import CoreApi from '../../api/core'
import { prettyDateTime } from '@/filters'

export default {
  name: 'WoodWingList',
  data () {
    return {
      dataLoaded: false,
      autoRefresh: true,
      articles: [],
      dataTableConfig: {
        fields: {
          id: this.$t('system.id'),
          'field.title': this.$t('article.title'),
          site: this.$t('article.site'),
          issue: this.$t('woodWing.issue'),
          date: this.$t('article.created_at'),
          articles: this.$t('article.status')
        },
        actions: {
          custom: [
            {
              route_name: 'woodWing_detail',
              route_param: 'id',
              route_source_param: 'id',
              icon: 'fa-info',
              title: this.$t('dashboard.show'),
              text: this.$t('dashboard.show')
            }
          ]
        },
        render: {
          site: (id) => '<span class="text-nowrap">' + this.siteNameById(id) + '</span>',
          date: prettyDateTime,
          articles: (data) => '<span class="label ' + (data.length ? 'btn-green' : 'label-inverse') + ' text-uppercase"><strong>' +
            this.$t(data.length ? 'article.status_published' : 'topicOffer.status.new') + '</strong></span>',
          issue: (id) => this.getIssueTitle(id)
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    woodWing () {
      return this.$store.getters['woodWing/list']
    },
    totalCount () {
      return this.$store.getters['woodWing/totalCount']
    },
    page () {
      return this.$store.getters['woodWing/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appWoodWingFilter: WoodWingFilter,
    appDataTable: DataTable,
    appAutoloader: Autoloader,
    appCheckbox: Checkbox
  },
  methods: {
    getWoodWing () {
      this.$store.dispatch('woodWing/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    getArticleByDocumentId (documentId) {
      let articleObj = null
      this.articles.forEach(article => {
        if (article.documentId === documentId) {
          articleObj = article
        }
      })
      return articleObj
    },
    fetchArticles () {
      const articleDocumentIds = []

      this.woodWing.forEach(woodWing => {
        woodWing.articles.forEach(article => {
          articleDocumentIds.push(article.articleDocumentId)
        })
      })

      if (articleDocumentIds.length === 0) {
        return
      }

      const url = '/article?view=expanded&filter_in[documentId]=' + articleDocumentIds.toString()
      CoreApi().get(url)
        .then(response => {
          if (response.data.totalCount > 0) {
            this.articles = response.data.data
          }
        })
        .catch(error => console.log(error))
    },
    setPageAndGetRecords (page) {
      this.$store.commit('woodWing/setPage', page)
      this.getWoodWing()
    },
    getIssueTitle (id) {
      const issue = this.$store.getters['woodWing/issues'].find((i) => i.id === id)
      return issue ? issue.title : (id ?? '')
    },
    siteNameById (id) {
      const site = this.$store.getters['site/siteById'](id)
      if (site) {
        return site.title
      }
      return id
    }
  },
  watch: {
    woodWing () {
      this.fetchArticles()
    }
  },
  async mounted () {
    await this.$store.dispatch('woodWing/fetchIssues')
    this.getWoodWing()
  }
}
</script>

<style scoped lang="scss">
  .top-buttons {
    position: absolute;
    top: -56px;
    right: 8px;
    z-index: 5;
  }
</style>
